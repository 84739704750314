import React from "react"
import { Link, graphql } from "gatsby"
import * as queryString from "query-string"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"

const Busqueda = ({ data, location}) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const { keywords } = queryString.parse(location.search)
  const breadcrumbs = [
    { name: 'Inicio', link: '/' },
    { name: 'Búsqueda', link: location.pathname + location.search },
  ]

  if (keywords && keywords.length > 2) {
    const filteredData = posts.filter(post => {
      const { title } = post.frontmatter
      return (
        title.toLowerCase().includes(keywords.toLowerCase())
      )
    })

    if (filteredData.length === 0) {
      return (
        <Layout location={location} title={siteTitle}>
          <SEO title={"Búsqueda para: " + keywords + " | Esto Apesta"} />
          <div className="mb-4">
            <h1 className="h3 h4-xs font-weight-medium mb-0">
              {"Búsqueda para: " + keywords}
            </h1>
            <Breadcrumbs links={breadcrumbs} />
          </div>
          <div className="article-format">
            <p className="fs--20">
              Lo sentimos, no se encontró ningún post relacionado con tu búsqueda.
            </p>
          </div>
        </Layout>
      )
    }

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={"Búsqueda para: " + keywords + " | Esto Apesta"} />
        <div className="mb-4">
          <h1 className="h3 h4-xs font-weight-medium mb-0">
            {"Búsqueda para: " + keywords}
          </h1>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <div className="row">
          {filteredData.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            return (
              <Link key={post.fields.slug} to={post.fields.slug} itemProp="url" className="col-12 col-md-6 mb-5 position-relative text-dark clearfix text-decoration-none">
                <figure className="d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden">
                  <div className="text-center bg-light h--250 rounded bg-cover lazy"
                    data-loaded="true"
                    style={{ backgroundImage: `url(${post.frontmatter.featured_image})` }}
                  ></div>
                </figure>

                <p className="mt-3 fs--20 mb-2">
                  <span className="h6-xs text-dark d-block">
                    {title}
                  </span>

                  <span className="d-block text-muted font-regular fs--14">
                    Publicado: <time dateTime="{post.frontmatter.date}">{post.frontmatter.date}</time>
                  </span>
                </p>
              </Link>
            )
          })}
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Búsqueda para: | Esto Apesta" />
        <div className="mb-4">
          <h1 className="h3 h4-xs font-weight-medium mb-0">
            {"Búsqueda para: "}
          </h1>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <div className="article-format">
          <p className="fs--20">
            Es necesario ingresar un término de búsqueda.
          </p>
        </div>
      </Layout>
    )
  }
}

export default Busqueda

export const pagQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(
            formatString: "DD MMMM YYYY",
            locale: "es-MX"
          )
          title
          description
          featured_image
        }
      }
    }
  }
`